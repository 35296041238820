
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.el-checkbox .is-checked .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    /deep/.is-indeterminate .el-checkbox__inner{
        background-color: #FD4446;
        border-color: #FD4446;
    }
    .PromotionTwo{
        padding: 0 20px 20px 20px;
        .PromotionTwoHeader{
            color: #343434;
            font-size: 18px;
        }
        .PromotionTwoTable{
            .shopData{
                display: flex;
                align-items: center;
                /*justify-content: center;*/
                img{
                    width: 48px;
                    height: 48px;
                }
                span{
                    color: #333333;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    b{
                        font-weight: 500;
                        display: inline-block;
                        width: 30px;
                        border: 1px solid #39CD7C;
                        color: #39CD7C;
                        font-size: 10px;
                        /*padding: 3px 6px;*/
                        height: 16px;
                        text-align: center;
                        line-height: 16px;
                    }
                }
            }
        }
        .setSku{
            padding-top: 60px;
            color: #343434;
            font-size: 16px;
            b{
                font-weight: 500;
                color: #FD4446;
            }
        }
        .footer{
            padding-top: 58px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footerLeft{
                .btn{
                    width: 100px;
                    height: 40px;
                    line-height: 0;
                }
                .btn:nth-child(2){
                    background: #FD4446;
                    border-color: #FD4446;
                    color: #ffffff;
                }
            }
        }
    }
    /deep/ .el-button.is-disabled {
        background-color: #DCDCDC !important;
        border-color: #DCDCDC !important;
    }
